#wpadminbar{
	display: none;
}
html{
	margin-top: 0!important;
}
body{
	padding-top: 80px;
	@include max-screen(1001px){
		padding-top: 50px;
	}
}
.container, .l-container{
	max-width: 1040px;
	padding: 0 20px;
}
body.complete{
	@include max-screen(767px){
		.l-section.is-bottom .l-container{padding: 0;}
		.l-post-content p {@include font(16,27,0.06);}
	}
	@include max-screen(370px){
		.l-post-content p {@include font(14,24,0.02);}
	}
  .heading.is-lg{
    border-radius: 0;
    background: rgba(218,223,237,0.5);
    color: #4D65A6;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: .06em;
    font-weight: bold;
    padding: 15.5px 20px;
    @include max-screen(767px){
    	font-size: 16px;
    	line-height: 26px;
    	padding: 10.5px 20px;
    }
    @include max-screen(370px){
    	font-size: 14px;
    	letter-spacing: 0;
    }
  }
  .heading.is-xlg{
    font-size: 42px;
    line-height: 60px;
    letter-spacing: .06em;
    font-weight: 500;
    font-family: 'Noto Serif JP', serif;
    border: none;
    color: $colortext;
    text-align: left;
    @include max-screen(767px){
      font-size: 30px;
      line-height: 44px;
      letter-spacing: .06em;
      font-weight: 500;
      margin: 0 auto 30px;
    }
    @include max-screen(370px){font-size: 26px;}
  }
}
.c-h1{
	@include font(42,60,0.06,500);
	@include noto-serif;
	position: relative;
	@include max-screen(767px){
		font-size: 30px;
		line-height: 44px;
	}
	
}
.visual1{
	padding: 0 20px;
	width: 100%;
	.wcm{
		max-width: 1000px;
		padding: 0;
		background: rgba(218,223,237,0.35) url(/assets/img/products/visual.png) right 2% bottom 14%/auto 70% no-repeat;
		position: relative;
		&:before{
			content: "";
			position: absolute;
			top: 0;
			right: 100%;
			width: 100%;
			height: 100%;
			background: rgba(218,223,237,0.35);
			z-index: -1;
			@include max-screen(767px){
				right: 0px;
				width: calc(100% + 20px);					
			}
		}
		@include max-screen(767px){
			background: url(/assets/img/products/visual_sp.png) center bottom -8px/315px auto no-repeat;
		}
	}
	&__ttl{
		@include font(42,60,0.06,500);
		@include noto-serif;
		top: -32px;
		margin-bottom: -32px;
		position: relative;
		@include max-screen(767px){
			font-size: 30px;
			line-height: 44px;
			top: -22px;
			margin-bottom: -22px;
		}
	}
	&__txt{
		@include font(18,46,0.08);
		padding: 50px 0 78px;
		@include max-screen(767px){
			font-size: 16px;
			line-height: 36px;
			max-width: 288px;
			width: 100%;
			padding: 25px 0 128px;
		}
		@include max-screen(370px){
			max-width: calc(100% - 20px);
		}
	}
}
/*-----------------------------------------------------------
SubNavi
------------------------------------------------------------*/
a.c-btn,.c-btn{
	display: inline-block;
	@include font(14,24,0.04,bold);
	text-align: center;
	border-radius: 5px;
	border: 1px solid $purple;
	position: relative;
	padding: 11px 33px 7px 23px;
	overflow: hidden;
	@include IE{
		padding: 13px 33px 5px 23px;
	}
	&:before{
		content: "";
		position: absolute;
		right: 11px;
		top: calc(50% - 3px);
		width: 6px;
		height: 6px;
		border-top: 1px solid $purple;
		border-left: 1px solid $purple;
		@include transform_c(rotate(135deg));
		@include transition_c(right 0.3s);
	}
	p{
		position: relative;
		z-index: 2;
	}
	em{
		font-style: normal;
		font-size: 11px;
		line-height: 11px;
		display: block;
		margin-bottom: -1px;
		@include max-screen(767px){
			font-size: 10px;
			letter-spacing: 0.04em;
		}
	}
	&--white{
		color: $purple;
		&:after{
			background: $purple;
			opacity: 0.1;
		}	
	}
	&--purple{
		background: $purple;
		color: #fff;
		&:before{border-color: #fff;}
		&:after{
			background: #475E9D;
			opacity: 0.8;
		}
	}
	&--slice,&--cart{
		&:before{content: none;}
		@include screen(1002px,1100px){
			padding: 5px 10px;
			p{
				font-size: 13px;
				em{font-size: 11px;}
			}
		}
		@include screen(768px,1001px){
			padding: 5px 5px;
			p{
				font-size: 10px;
				em{font-size: 8px;}
			}
		}
		@include max-screen(767px){
			p{display: inline-block;}
		}
	}
	&--slice p{
		background: url(../img/common/ico_slice.svg) left 9px center/16px 27px no-repeat;
		backface-visibility: hidden;
		@include screen(768px,1100px){
			background-size: 13px 23px;
			background-position: left 6px center;
		}
		@include max-screen(767px){
			background: url(../img/common/ico_slice_purple.svg) left 5px center/14px auto no-repeat;
		}
	}
	&--cart p{
		background: url(../img/common/ico_cart.svg) left 3px center/24px 20px no-repeat;
		@include screen(768px,1100px){
			background-size: 18px 15px;
		}
		@include max-screen(767px){
			background: url(../img/common/ico_cart_white.svg) left 6px center/19px auto no-repeat;
		}
	}
	@include min-screen(768px){
		&:hover{
			opacity: 1;
			background-color: #DADFED;
			color: $purple;
			&:before{right: 20px;}
			&.c-btn--purple:before{border-color: $purple;}
			&.c-btn--white:before{border-color: #fff;}
			&.c-btn--slice p{background-image: url(../img/common/ico_slice_purple.svg);}
		}
	}
}
.c-link{
	display: inline-block;
	padding-bottom: 30px;
	border-bottom: 1px solid #2C2C2C;
	@include min-screen(768px){
		&:hover{
			opacity: 1;
			border-color: $purple;
			p,p:before{
				color: $purple;
				border-color: $purple;
			}
		}
	}
	@include screen(1001px,1024px){padding-bottom: 20px;}
	@include screen(768px,1000px){padding-bottom: 15px;}
	@include max-screen(767px){padding-bottom: 18px;}
	@include max-screen(370px){padding-bottom: 12px;}
	p{
		padding: 0 76px 0px 20px;
		@include font(18,22,0.04,bold);
		position: relative;
		color: $colortext;
		@include transition_c(all 0.3s);
		@include screen(768px,1000px){
			font-size: 16px;
			padding-right: 60px;
		}
		@include max-screen(767px){
			font-size: 14px;
			line-height: 17px;
			padding: 0 35px 0px 13px;
		}
		&:before{
			content: "";
			position: absolute;
			top: calc(50% - 5px);
			right: 10px;
			width: 9px;
			height: 9px;
			border-top: 1px solid $colortext;
			border-left: 1px solid $colortext;
			@include transform_c(rotate(135deg));
			@include transition_c(all 0.3s);
			@include IE{top: calc(50% - 7px);}
			@include max-screen(767px){
				width: 6px;
				height: 6px;
				top: calc(50% - 3px);
			}
		}
	}
}
/*-----------------------------------------------------------
Title
------------------------------------------------------------*/
.c-txt{
	@include font(18,46,0.08);
	@include max-screen(767px){
		font-size: 16px;
		line-height: 36px;
	}
	@include max-screen(370px){
		font-size: 14px;
		line-height: 28px;
		letter-spacing: 0.04em;
	}
}
.c-ttl{
	text-align: center;
	color: $purple;
	@include font(30,44,0.06,500);
	@include noto-serif;
	@include max-screen(767px){
		font-size: 20px;
		line-height: 29px;
	}
}
.c-page-header{
	text-align: left;
	padding: 30px 0 0;
	@include max-screen(767px){padding-top: 5px;}
	h1{
		@include font(42,60,0.06,500);
		@include noto-serif;
		@include max-screen(767px){@include font(30,44,0.06,500);}
		@include max-screen(370px){font-size: 28px;}
	}
}
.c-guidecontact{
	.heading.is-lg{
		border-radius: 0;
		background: rgba(218,223,237,0.5);
		color: $purple;
		@include font(20,24,0.06,bold);
		padding: 15.5px 20px;
		@include max-screen(767px){
			font-size:16px;
			line-height: 26px;
			padding: 10.5px 20px;
		}
	}
	.txt1,.txt2,a{
		@include font(16,26,0.06,400);
		@include max-screen(767px){
			line-height: 30px;
			letter-spacing: 0.08em;
		}
		@include max-screen(370px){
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.04em;
		}
	}
	.txt1{
		padding-top: 6px;
		@include max-screen(767px){padding-top: 8px;}
	}
	.txt2{
		margin-top: 26px;
		@include max-screen(767px){margin-top: 30px;}
		@include max-screen(370px){margin-top: 20px;}
	}
	a{
		text-decoration: underline;
		margin-top: 27px;
		display: inline-block;
		@include max-screen(767px){margin-top: 30px;}
		@include max-screen(370px){margin-top: 20px;}
	}
}
/*-----------------------------------------------------------
LST
------------------------------------------------------------*/
.pro-lst{
	display: flex;
	justify-content: flex-start;
	flex: none;
	flex-wrap: wrap;
	@include max-screen(767px){display: block;}
	.item{
		width: calc(33.33% - 18px);
		text-align: center;
		position: relative;
		padding-bottom: 45px;
		@include max-screen(767px){
			padding-bottom: 47px;
			width: 100%;
			&:not(:last-child){margin-bottom: 68px;}
		}
		&:first-child .txt{@include min-screen(768px){letter-spacing: -0.01em;}}
		&:not(:nth-child(3n)){margin-right: 27px;}
		.note{
			text-align: center;
			display: inline-block;
			width: 100%;
			@include font(18,26,0.04,bold);
			background: #EFEFEF;
			position: relative;
			padding: 20px 10px;
			@include IE{padding: 23px 10px 17px;}
			@include screen(768px,1024px){
				font-size: 16px;
				padding: 15px 10px;
			}
			@include max-screen(767px){
				font-size: 16px;
				line-height: 24px;
				padding: 20px 17px 18px 10px;
			}
			@include max-screen(370px){padding: 12px 10px 10px;}
			&:before{
				content: "";
			  position: absolute;
			  height: 0;
			  width: 0;
			  bottom: -19px;
			  left: calc(50% - 25px);
			  border-left: 25px solid transparent;
			  border-right: 25px solid transparent;
			  border-top: 20px solid #EFEFEF;
			}
		}
		.heading{
			color: $purple;
			@include font(20,30,0.06,500);
			@include noto-serif;
			padding: 19px 0 8px;
			@include screen(768px,1024px){
				font-size: 18px;
				letter-spacing: 0.03em;
				line-height: 28px;
				padding: 10px 0 3px;
			}
			@include max-screen(767px){
				font-size: 17px;
				line-height: 26px;
				padding: 10px 15px 0 0;
			}
		}
		.img{width: 100%;}
		.logo{
			width: 100%;
			height: auto;
			display: inline-block;
			min-height: 85px;
			@include screen(768px,1024px){
				min-height: 60px;
			}
			@include max-screen(767px){min-height: 0px;}
			&1 img{
				max-width: 250px;
				margin: 44px auto 0;
				@include screen(768px,1024px){
					max-width: 175px;
					margin-top: 31px;
				}
				@include max-screen(767px){max-width: 231px;}
			}
			&2 img{
				max-width: 132px;
				margin: 32px auto 0;
				@include screen(768px,1024px){
					max-width: 92px;
					margin-top: 22px;
				}
				@include max-screen(767px){
					max-width: 122px;
					padding: 5px 0 2px;
				}
			}
			&3 img{
				max-width: 149px;
				margin-top: 30px;
				@include screen(768px,1024px){
					max-width: 104px;
					margin-top: 21px;
				}
				@include max-screen(767px){max-width: 138px;}
			}
			img{
				@include max-screen(767px){
					margin-top: 25px;
					margin-bottom: 0;
				}
			}
		}
		.ttl{
			@include font(18,22,0.04,bold);
			padding: 15px 0 25px;
			@include screen(768px,1024px){padding: 10px 0 15px;}
			@include max-screen(767px){
				font-size: 16px;
				line-height: 19px;
				padding: 32px 15px 23px 0;
			}
			@include max-screen(370px){padding: 15px 0 10px;}
		}
		.txt{
			@include font(14,20,0.04);
			text-align: left;
			min-height: 100px;
			@include screen(768px,1000px){letter-spacing: 0em;}
			@include IE{flex: 0 auto 1;}
			@include max-screen(767px){min-height: 0;}
			em{
				font-style: normal;
				font-size: 12px;
				display: block;
			}
		}
		.link{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			@include min-screen(768px){
				a:hover{
					opacity: 1;
					color: $purple;
				}
			}
			@include max-screen(767px){margin-top: 15px; }
			a{
				display: inline-block;
				padding: 0 10px 6px;
				border-bottom: 1px solid currentColor;
				@include font(11,13,0.04,500);
				color: $colortext;
				@include max-screen(767px){
					font-size: 12px;
					line-height: 14px;
					padding: 0 20px 11px 16px;
				}
			}
		}
	}
	+.c-btn{
		max-width: 330px;
		width: 100%;
		font-size: 22px;
		display: block;
		margin: 57px auto 0;
		padding: 18px 15px;
		@include IE{padding: 20px 15px 16px;}
		@include max-screen(767px){
			max-width: 250px;
			font-size: 18px;
			padding: 12px 15px;
			margin-top: 70px;
		}
		&:before{
			width: 8px;
			height: 8px;
			right: 25px;
			top: calc(50% - 4px);
			@include max-screen(767px){right: 20px;}
		}
	}
}
/*-----------------------------------------------------------
Title
------------------------------------------------------------*/
.btn-search{
	text-align: center;
	a{
		display: inline-block;
		width: 100%;
		max-width: 486px;
		border: 3px solid $purple;
		padding: 31px 10px;
		border-radius: 0;
		@include IE{padding: 34px 10px 28px;}
		@include screen(768px,1100px){
			padding: 25px 10px;
			max-width: 440px;
			@include IE{padding: 28px 10px 22px;}
		}
		@include max-screen(767px){padding: 20px 10px;}
		&:before{content: none;}
		p,span{
			display: inline-block;
			padding-left: 62px;
			@include font(24,42,0.04,bold);
			background: url(../img/common/ico_search.svg) left center/30px 30px no-repeat;
			@include screen(768px,1100px){
				font-size: 22px;
				padding-left: 45px;
				background-size: 28px 28px;
			}
			@include max-screen(767px){
				font-size: 20px;
				line-height: 32px;
				background-size: 22px 22px;
				padding-left: 45px;
			}
			@include max-screen(370px){
				font-size: 18px;
				padding-left: 33px;
			}
		}
	}
}