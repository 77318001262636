@charset 'UTF-8';
/*-----------------------------------------------------------
Bread
------------------------------------------------------------*/
.breadcrumb{
	margin-top: 14px;
	@include max-screen(767px){
		margin-top: 9px;
	}
	.c-breadcrumb{
		margin-top: 0;
		margin-bottom: 0;
	}
	.wcm{
		max-width: 1040px;
	}
	*{
		color: $colortext;
	}
	a,span{
		@include font(12,20,0.04,400);
		@include min-screen(768px){
			&:hover{
				color: $purple;
				opacity: 1;
			}
		}
		@include max-screen(767px){
			font-size: 10px;
			line-height: 16px;
		}
	}
	a{
		text-decoration: underline;
	}
}