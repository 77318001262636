@charset 'UTF-8';
/*-----------------------------------------------------------
SubNavi
------------------------------------------------------------*/
.mainvisual{
	display: flex;
	justify-content: space-between;
	position: relative;
	height: calc(100vh - 80px);
	@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    background-attachment: inherit;
    height: calc(100vh - 120px);
  }
  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    background-attachment: inherit;
    height: calc(100vh - 120px);
  }
	@include max-screen(767px){
		height: auto;
	}
	&:before{
		content: "";
		width: 100%;
		height: 62.74%;
		max-height: 352px;
		position: absolute;
		top: 16%;
		left: 0;
		background: #EFEFEF;
		z-index: -1;
		@include screen(768px,1024px){
			height: 40%;
		}
		@include max-screen(767px){
			content: none;
		}
	}
	@include max-screen(767px){
		display: block;
	}
	&__left{
		// width: 70.21%;
		width: calc(100% - 417px);
		background: url(/assets/img/top/visual1_right.jpg) top right/5px 100% repeat-x;
		position: relative;
		text-align: center;
		overflow: hidden;
		@include screen(768px,1000px){
			width: 60%;
		}
		@include max-screen(767px){
			width: 100%;
		}
		&--img{
			// max-width: 983px;
			width: 100%;
			height: 100%;
			display: inline-block;
			vertical-align: top;
			position: relative;
			background: url(/assets/img/top/visual01.jpg) center top/auto 100% no-repeat;
		}
		&:before{
			content: "";
			width: 50%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			background: url(/assets/img/top/visual1_bg.jpg) left top/ 5px 100% repeat-x;
		}
		&--h1{
			@include font(40,64,0.06,500);
			@include noto-serif;
			position: absolute;
			bottom: 65px;
			left: 86px;
			z-index: 1;
			@include screen(768px,1024px){
				font-size: 30px;
				line-height: 1.6;
				left: 6.5%;
				bottom: 8%;
			}
			@include max-screen(767px){
				font-size: 22px;
				line-height: 34px;
				left: 26px;
				bottom: calc(50% - 15px);
			}
			@include max-screen(370px){
				font-size: 18px;
				line-height: 30px;
				left: 15px;
				bottom: calc(50% - 25px);
			}
		}
	}
	&__right{
		// width: 29.79%;
		width: 417px;
		max-height: 561px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: right;
		@include screen(768px,1000px){
			width: 40%;
			display: block;
		}
		@include max-screen(767px){
			display: block;
			width: 100%;
			position: relative;
			&:before{
				content: "";
				width: calc(100% - 20px);
				height: 79.4%;
				position: absolute;
				top: 0;
				right: 0;
				background: #EFEFEF;
				z-index: -1;
			}
		}
		.img{
			position: relative;
			overflow: hidden;
			text-align: center;
			&:before{
				content: "";
				width: 50%;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				z-index: 0;
			}
			@include max-screen(767px){
				background: none;
			}
		}
		&--top{
			display: block;
			width: 100%;
			height: 52.6%;
			padding: 11.2% 24.2% 4.9% 0;
			@include screen(768px,1000px){
				height: auto;
			}
			@include max-screen(767px){
				padding: 0 66px 12px 86px;
				height: auto;
			}
			.img{
				width: calc(100% + 27px);
				position: relative;
				left: -27px;
				z-index: 2;
				height: 100%;
				display: block;
				background: url(/assets/img/top/visual2_bg.jpg) top left/5px 100% repeat-x;
				&:before{
					background: url(/assets/img/top/visual2_bg2.jpg) top left/5px 100% repeat-x;
				}
				@include max-screen(767px){
					width: 100%;
					left: 0;
					margin-top: -25px;
				}
			}
		}
		&--bottom{
			width: 100%;
			height: 47.4%;
			padding: 0 32px 40px 45px;
			@include screen(768px,1000px){
				height: auto;
			}
			@include max-screen(767px){
				height: auto;
				padding: 0 20px 0 133px;
			}
			.img{
				width: 100%;
				height: 100%;
				display: block;
				background: url(/assets/img/top/visual3_bg.jpg) top left/4px 100% repeat-x;
				&:before{
					background: url(/assets/img/top/visual3_bg2.jpg) top left/4px 100% repeat-x;
				}
			}
		}
		img{
			display: inline-block;
			max-height: 100%;
			position: absolute;
			height: 100%;
			width: auto;
			max-width: 300%;
			top: 0;
			right: 50%;
			@include transform_c(translateX(50%));
			@include min-screen(1400px){
				position: static;
				@include transform_c(translateX(0%));
			}
			@include screen(768px,1000px){
				position: static;
				width: 100%;
				height: auto;
				@include transform_c(translateX(0%));
			}
			@include max-screen(767px){
				width: 100%;
				position: static;
				height: auto;
				max-width: 100%;
				@include transform_c(translateX(0%));
			}
		}
	}
}