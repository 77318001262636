.loading {
	display: flex;
	z-index: 999999;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	transition-property: z-index;
	transition-duration: 1.6s;
	transition-delay: 4s;
}
.loading.hide {
	pointer-events: none;
	z-index: -1;

}
.loading_bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	will-change: opacity;
	pointer-events: none;
	transition-property: opacity;
	transition-duration: 1.6s;
	transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
	transition-delay: 0.4s;
}
.loading.hide .loading_bg{
	opacity: 0;
}
.loading_logo {
	position: relative;
	width: 325px;
	pointer-events: none;
	will-change: opacity, transform;
	transition-property: opacity, transform;
	transition-duration: .8s;
	transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
	@include max-screen(767px){
		width: 180px;
	}
	img{
		width: 100%;
	}
}
.loading.hide .loading_logo {
	opacity: 0;
	transform: scale3d(1.05, 1.05, 1)
}
.filter2{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	transition-property: filter;
	transition-duration: 1.4s;
	transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
	overflow: hidden;
	filter: brightness(150%);
}
.filter3{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	z-index: 3;
	-webkit-mask-image: radial-gradient(circle farthest-corner at 100% 0%, #000 60%, transparent 90%);
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-size: 200% 100%;
	-webkit-mask-position: 100% 0;
	transition-property: -webkit-mask-position;
	transition-property: mask-position;
	transition-property: mask-position, -webkit-mask-position;
	transition-duration: 1.4s;
	transition-timing-function: cubic-bezier(0.3, 0.1, 0.1, 1);
}