@charset 'UTF-8';
.footer{
	width: 100%;
	background: $purple;
	padding: 80px 0 86px;
	@include screen(768px,1100px){
		padding: 50px 0 35px;
	}
	@include max-screen(767px){
		padding: 5px 0 39px;
	}
	a:link, a:visited, a:active {
	  text-decoration: none;
	}
	.wcm{
		display: flex;
		justify-content: space-between;
		flex: none;
		flex-wrap: wrap;
		color: #fff;
		@include max-screen(767px){
			display: block;
		}
	}
	&__left{
		width: 330px;
		text-align: center;
		@include screen(768px,1100px){
			width: 250px;
		}
		@include max-screen(767px){
			width: 100%;
			padding-top:52px;
		}
		.c-btn{
			margin: 6px auto 0;
			width: 100%;
			background: #DADFED;
			color: $purple;
			@include font(19,31,0.04,bold);
			padding: 6px 10px 4px;
			@include IE{
				padding: 9px 10px 1px;
			}
			@include min-screen(768px){
				&:hover{
					background: #fff;
				}
			}
			@include max-screen(767px){
				font-size: 16px;
				line-height: 29px;
			}
			&:before{
				content: none;
			}
		}
		.add{
			@include font(16,26,0.06,400);
			padding-top: 15px;
			@include screen(768px,1100px){
				font-size: 13px;
				line-height: 22px;
			}
			@include max-screen(767px){
				font-size: 14px;
				line-height: 22px;
				padding: 15px 8px 25px 0;
			}
			@include max-screen(370px){
				font-size: 12px;
				line-height: 18px;
			}
		}
		.btn-tel{
			display: inline-block;
			@include max-screen(767px){
				margin-top: 14px;
			}
			p{
				color: #fff;
				@include font(43,70,0.02,bold);
				padding-left: 33px;
				background: url(/assets/img/common/ico_tel.svg) left center/22px 36px no-repeat;
				@include IE{
					background-position: left top 10px;
				}
				@include screen(768px,1100px){
					font-size: 28px;
					background-size: 17px 27px;
					padding-left: 25px;
					white-space: nowrap;
					letter-spacing: 0;
					@include IE{
						background-size: 15px 24px;
						background-position: left top 17px;
					}
				}
				@include max-screen(767px){
					padding:0 5px 0 38px;
					line-height: 1;
					letter-spacing: 0;
					display: inline-block;
					font-size: 38px;
					background-position: left 5px center;
				}
				@include max-screen(370px){
					font-size: 30px;
					background-size: 16px 26px;
					padding-left: 28px;
				}
			}
		}
		.time{
			@include font(12,20,0.06,400);
			@include max-screen(767px){
				font-size: 14px;
				line-height: 22px;
				padding-top: 10px;
			}
			@include max-screen(370px){
				font-size: 12px;
				line-height: 20px;
			}
		}
		&--logo{
			margin: 53px auto 0;
			@include screen(768px,1100px){
				margin-top: 40px;
			}
			@include max-screen(767px){
				margin-top: 50px;
			}
			img{
				max-width: 284px;
				@include screen(768px,1100px){
					max-width: 100%;
				}
				@include max-screen(767px){
					max-width: 295px;
				}
				@include max-screen(370px){
					max-width: 240px;
				}
			}
		}
	}
	&__right{
		width: calc(100% - 330px);
		@include max-screen(767px){
			width: 100%;
		}
		@include screen(768px,1100px){
			width: calc(100% - 250px);
		}
		&--lst{
			display: flex;
			justify-content: space-between;
			@include max-screen(767px){
				display: block;
				padding-top: 45px;
			}
			.item{
				&:nth-child(1){
					width: 42.5%;
					@include screen(768px,1100px){
						width: 55%;
					}
					@include max-screen(767px){
						width: 100%;
					}
				}
				&:nth-child(2){
					width: 57.5%;
					@include screen(768px,1100px){
						width: 45%;
					}
					@include max-screen(767px){
						width: 100%;
					}
				}
				li{
					padding-bottom: 17px;
					@include max-screen(767px){
						padding-bottom: 15px;
					}
					a{
						@include font(14,24,0.06,400);
						color: #fff;
						@include screen(768px,1100px){
							font-size: 12px;
							letter-spacing: 0.02em;
						}
					}
				}
			}
		}
		&--copy{
			width: 100%;
			@include font(10,20,0.06,400);
			margin-top: -9px;
			@include screen(768px,1100px){
				margin-top: 0;
			}
			@include max-screen(767px){
				text-align: center;
				padding-top: 32px;
			}
			@include max-screen(370px){
				letter-spacing: 0.02em;
			}
		}
	}
	.grBtn{
		@include max-screen(767px){
			opacity: 1;
			bottom:0;
			margin-top: 0px;
			padding-top: 11px;
		}
	}
}