@charset 'UTF-8';
.header{
	a:link, a:visited, a:active {
	  text-decoration: none;
	}
	display: flex;
	justify-content: space-between;
	padding: 15px 18px 15px 40px;
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	background: #fff;
	width: 100%;
	height: 80px;
	@include max-screen(1200px){
		padding: 15px 15px;
	}
	@include max-screen(1001px){
		height: 50px;
		padding: 0 0 0 19px;
	}
	&__lst,&__btn{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		@include max-screen(1001px){
			display: block;
		}
	}
	&__lst{
		width: calc(100% - 471px);
		justify-content: flex-start;
		@include screen(1002px,1200px){
			width: calc(100% - 360px);
		}
		@include max-screen(1001px){
			width: calc(100% - 50px);
		}
		&--logo a{
			display: inline-block;
			@include max-screen(1001px){
				max-width: 198px;
				margin-top: 11px;
			}
			@include max-screen(1001px){
				margin-top: 13px;
			}
			img{
				width: 100%;
				max-width: 276px;
				@include screen(1002px,1200px){
					max-width: 240px;
				}
			}
		}
		&--item{
			padding-left: 39px;
			@include IE{
				padding-top: 2px;
			}
			&:nth-child(2){
				padding-left: 60px;
				@include screen(768px,1200px){
					padding-left: 30px;
				}
			}
			@include max-screen(1200px){
				padding-left: 40px;
			}
			@include screen(768px,1200px){
				padding-left: 25px;
				&:first-child{
				}
			}
			a{
				@include font(14,24,0.04);
				color: $colortext;
				@include min-screen(768px){
					&:hover{
						opacity: 1;
						color: $purple;
					}
				}
			}
		}
	}
	&__btn{
		width: 471px;
		display: flex;
		justify-content: space-between;
		padding-top: 5px;
		@include screen(1002px,1200px){
			width: 360px;
		}
		&--item{
			width: calc(50% - 4px);
			@include screen(768px,1200px){
				width: calc(50% - 3px);
			}
			.c-btn{
				width: 100%;
				padding: 13px 15px 13px 10px;
				text-align: center;
				@include font(14,24,0,bold);
				@include IE{
					padding-top: 14px;
					padding-bottom: 12px;
				} 
				@include screen(1002px,1200px){
					font-size: 12px;
					padding: 11px 10px 11px 5px;
				}
				&:before{
					width: 8px;
					height: 8px;
					top: calc(50% - 4px);
					right: 12px;
					@include screen(1002px,1200px){
						width: 6px;
						height: 6px;
						top: calc(50% - 2px);
						right: 8px;
					}
				}
				@include min-screen(768px){
					&:hover:before{
						right: 7px;
						border-color: $purple !important;
					}
				}
			}
		}
	}
	.hamburger{
		width: 50px;
		height: 50px;
		position: relative;
		@include transition_c(all 0.3s);
		span{
			display: block;
			width: 26px;
			position: absolute;
			background: #707070;
			height: 2px;
			top: 50%;
			left: calc(50% - 13px);
			@include transition_c(all 0.3s);
			&:before,&:after{
				content: "";
				width: 100%;
				height: 2px;
				background: #707070;
				position: absolute;
				left: 0;
				@include transition_c(all 0.3s);
			}
			&:before{
				top: -8px;
			}
			&:after{
				top: 8px;
			}
		}
		&.open{
			background: $purple;
			span{
				background: transparent;
				&:before,&:after{
					background: #fff;
					width: 22px;
				}
				&:after{
					transform: rotateZ(45deg) scaleX(1.25) translate(-3px, -7px);
				}
				&:before{
					transform: rotateZ(-45deg) scaleX(1.25) translate(-3px, 7px);
				}
			}
		}
	}
}
body{
	@include max-screen(1001px){ 
		&:after{
			content: "";
			height: 100%;
			position: fixed;
			width: 100%;
			top: 0;
			right: 0;
			background: $purple;
			opacity: 0;
			z-index: -1;
			@include transition_c(all 0.4s);
		}
	}
	&.menu-open{
		&:after{
			opacity: 0.4;
			z-index: 2;
		}
	}
}
.menu-sp{
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: -1;
	height: 100%;
	overflow: auto;
	@include transition_c(all 0.4s);	
	&.open{
		left: 0;
		opacity: 1;
		z-index: 999;
		.menu-sp__lst--item{
			@for $i from 0 through 10 {
			  &:nth-child(#{$i}) a{
			  	transition-delay: $i * 60ms;
					bottom:0;
					opacity: 1;
			  }
			}
		}
		.grBtn{
			opacity: 1;
			transition-property: opacity;
			transition-duration: 0.3s;
			transition-delay: 600ms;
		}
		.inner:before{
			height: 100%;
			opacity: 1;
		}
	}
	a:link, a:visited, a:active {
	  text-decoration: none;
	}
	.inner{
		width: 100%;
		height: auto;
		overflow: auto;
		padding: 75px 20px 64px;
		position: relative;
		z-index: 1;
		&:before{
			content: "";
			width: 100%;
			height: 0;
			background: $purple;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			z-index: 0;
			@include transition_c(all 0.6s);
		}
	}
	&__lst{
		&--item{
			text-align: left;
			padding-bottom: 15px;
			a{
				color: #fff;
				@include font(14,24,0.06,500);
				position: relative;
				bottom: -10px;
				opacity: 0;
				transition-property: bottom,opacity;
				transition-duration: 0.3s;
			}
		}
	}
}
.grBtn{
	padding-top: 12px;
	display: flex;
	justify-content: space-between;
	@include max-screen(1001px){
		position: relative;
		opacity: 0;
	}
	.c-btn{
		width: calc(50% - 6px);
		text-align: left;
		padding: 15px 10px 13px;
		@include max-screen(1001px){
			text-align: center;
			padding: 14px 0 12px;
			line-height: 20px;
			letter-spacing: 0.04em;
			&:before{
				content: none;
			}
		}
		@include max-screen(370px){
			font-size: 11px;
			padding: 8px 5px;
		}
		&--white{
			background: #fff;
		}
		&--purple{
			border: 1px solid #fff;
		}
	}
}
body{
	@include max-screen(1001px){
		&.menu-open{
			overflow: hidden;
			position: fixed;
		}
	}
}