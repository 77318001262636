.page-template{
	&.company,&.site-policy,&.sitemap,&.privacy-policy{
		.l-content{
		>h2{
			border: none;
			text-align: left;
			@include font(30,44,0.06,500);
			@include noto-serif;
			margin-top: 13px;
			@include max-screen(767px){
				font-size: 28px;
				line-height: 40px;
			}
			@include max-screen(370px){
				font-size: 26px;
			}
		}
		h3{
			border-radius: 0;
			background: rgba(218,223,237,0.5);
			text-align: center;
			@include font(20,32,0.06,bold);
			padding: 11.5px 20px;
			@include IE{
				padding: 13.5px 20px 9.5px;
			}
			@include max-screen(767px){
				font-size: 16px;
				line-height: 26px;
				padding: 10.5px 20px;
			}
		}
	}
}
/*-----------------------------------------------------------
COMPANY
------------------------------------------------------------*/
&.company{
	.c-product-menu{
		padding: 38px 0;
		@include max-screen(767px){
			background: none;
			border: none;
			padding: 21px 0;
			margin-bottom: 15px;
		}
		li{
			padding: 0 70px 20px;
			border-bottom: 1px solid $colortext;
			border-left: none;
			border-right: none;
			@include max-screen(767px){
				display: inline-block;
				width: auto;
				padding: 0 48px 0px;
			}
			a{
				border: none;
				color: $colortext;
				font-weight: bold;
				@include max-screen(767px){
					font-size: 12px;
					line-height: 20px;
				}
			}
		}
	}
	.c-table.is-simple{
		margin-top: 12px;
		@include max-screen(767px){
			margin-top: 17px;
		}
		th{
			background: rgba(239,239,239,0.5);
			width: 159px;
			@include max-screen(767px){
				width: 104px;
			}
			@include max-screen(370px){
				width: 85px;
			}
		}
		td{
		}
		th,td{
			@include font(16,26,0.06,0.5);
			border-top: 1px solid #D8D8D8;
			color: $colortext;
			font-weight: normal;
			padding: 18px 27px 16px;
			vertical-align: middle;
			@include IE{
				padding: 20px 27px 14px;
			}
			@include max-screen(767px){
				font-size: 14px;
				line-height: 22px;
				padding: 12px 0px 12px 15px;
				vertical-align: top;
			}
			@include max-screen(370px){
				letter-spacing: 0.04em;
			}
		}
	}
	.row{
		.large-6:last-child{
			padding-left: 20px;
			@include max-screen(767px){
				padding-left: 0;
				table{
					margin-top: 0;
					tr:first-child{
						th,td{
							border-top: none;
						}
					}
				}
			}
		}
		.large-6{
			@include max-screen(767px){
				width: 100%;
				padding: 0;
			}
		}
	}
	.c-guidecontact{
		margin-top: 110px;
		margin-bottom: 124px;
		@include max-screen(767px){
			margin-top: 52px;
			margin-bottom: 97px;
		}
	}
}
/*-----------------------------------------------------------
SITE POLICY
------------------------------------------------------------*/
&.site-policy{
	.l-main{
		margin-top: 75px;
		padding-bottom: 78px;
		@include max-screen(767px){
			margin-top: 25px;
			padding-bottom: 37px;
		}
		h2{
			margin-bottom: 27px;
			@include max-screen(767px){
				margin-bottom: 13px;
				line-height: 40px;
			}
			&:not(:first-child){
				margin-top: 105px;
				@include max-screen(767px){
					margin-top: 48px;
				}
			}
			&:nth-of-type(2){
				margin-top: 120px;
				@include max-screen(767px){
					margin-top: 45px;
				}
			}
			&:nth-of-type(4){
				margin-top: 110px;
				@include max-screen(767px){
					margin-top: 43px;
					margin-bottom: 20px;
				}
			}
			&:nth-of-type(5){
				margin-top: 112px;
				@include max-screen(767px){
					margin-top: 45px;
				}
			}
			&:nth-of-type(6){
				margin-top: 115px;
				@include max-screen(767px){
					margin-top: 40px;
				}
			}
		}
		p{
			@include font(16,26,0.06,400);
			@include max-screen(767px){
				line-height: 30px;
				letter-spacing: 0.08em;
			}
			@include max-screen(370px){
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 0.04em;
			}
		}
		h3{
			margin-top: 50px;
			@include max-screen(767px){
				margin-top: 32px;
			}
			+p+h3{
				margin-top: 69px;
				@include max-screen(767px){
					margin-top: 25px;
				}
			}
			&:last-of-type{
				margin-top: 40px;
				margin-bottom: 36px;
				@include max-screen(767px){
					margin-bottom: 13px;
				}
			}
		}
		a{
			margin-top: 25px;
			display: inline-block;
		}
	}
}
/*-----------------------------------------------------------
Bread
------------------------------------------------------------*/
&.sitemap{
	.p-sitemap__title{
		@include font(16,26,0.06,bold);
	}
	.c-page-header +  .l-container{
		.l-container{
			padding: 0;
		}
	}
	.row{
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin: 77px auto 0;
		@include max-screen(767px){
			display: block;
			margin: 40px auto 0;
		}
		.large-6{
			float: none;
			padding: 0;
			@include max-screen(767px){
				display: block;
				width: 100%;
				&:not(:first-child){
					margin-top: 50px;
				}
			}
			@include min-screen(768px){
				&:nth-child(1){
					width: 39%;
					@include screen(768px,1024px){
						width: 32%;
					}
				}
				&:nth-child(2){
					width: 24%;
				}
				&:last-child{
					width: 37%;
					@include screen(768px,1024px){
						width: 44%;
					}
				}
			}
			ul{
				margin-top: 22px;
				li{
					width: 100%;
					@include font(14,20,0.04,400);
					list-style: none;
					margin-bottom: 18px;
					@include max-screen(767px){
						margin-bottom: 12px;
					}
					a{
						color: $colortext;
						padding-left: 18px;
						position: relative;
						display: inline-block;
						&:before{
							content: "";
							width: 5px;
							height: 5px;
							border-radius: 50%;
							background: $purple;
							position: absolute;
							top: 6px;
							left: 0;
						}
					}
				}
			}
		}
	}
}
/*-----------------------------------------------------------
PRIVACY POLICY 
------------------------------------------------------------*/
&.privacy-policy{
	h1{
		@include max-screen(370px){
			letter-spacing: 0;
		}
	}
	.l-main{
		padding: 75px 0 147px;
		@include max-screen(767px){
			padding: 27px 0;
		}
		h2{
			margin-bottom: 27px;
			@include max-screen(767px){
				margin-bottom: 10px;
			}
			&:not(:first-child){
				margin-top: 105px;
				@include max-screen(767px){
					margin-top: 50px;
				}
			}
			&:nth-of-type(4){
				margin-top: 90px;
				@include max-screen(767px){
					margin-top: 50px;
				}
			}
		}
		p{
			@include font(16,26,0.06,400);
			@include max-screen(767px){
				line-height: 30px;
				letter-spacing: 0.08em;
			}
			@include max-screen(370px){
				font-size: 14px;
				line-height: 24px;
			}
		}
		ul{
			margin: 35px 0;
			li{
				@include font(16,26,0.04,500);
				padding-left: 17px;
				margin-bottom: 13px;
				position: relative;
				@include max-screen(767px){
					line-height: 30px;
					letter-spacing: 0.08em;
				}
				@include max-screen(370px){
					font-size: 14px;
					line-height: 24px;
					padding-left: 15px;
				}
				&:before{
					content: "";
					position: absolute;
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background: $purple;
					left: 0;
					top: 10px;
					@include IE{
						top: 7.5px;
					}
				}
			}
		}
	}
}
/*-----------------------------------------------------------
Bread
------------------------------------------------------------*/
}